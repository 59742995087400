import React, { useState, useEffect } from 'react';
import './Comparison.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import InfoCard from './InfoCard';

const ComparisonProduct = () => {
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );

  const [infoo, setInfoo] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(false); // Track loading state
  const [message, setMessage] = useState(''); // State to store the message
  const [Report, setReport] = useState(''); // State to store the message    
  const [QuoteId, setQuoteId] = useState(''); // State to store the message
  const [comparisonData, setComparisonData] = useState([]); // Store the API response data
  const [Clearmsg, setClearmsg] = useState(''); // Clear session storage msg
  const comparisonString = comparisonList.join(',');

  // Function to clear the comparison list from session storage and reset state
  const handleClearComparison = () => {
    sessionStorage.removeItem('comparisonList');
    setComparisonData([]);  // Reset comparisonData to clear the table
    setComparisonList([]);
    setClearmsg("Remove product from comparison successfully.");

    setTimeout(() => {
      setClearmsg('');
    }, 2000); // 2000ms = 2 seconds
  };

  useEffect(() => {
    // Call the web services when the component loads
    const fetchData = async () => {
      setLoading(true); // Set loading to true at the start of the process

      try {
        // First web service call: setComaparisionPoint
        const firstResponse = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/setQuoteMasterDetails', {
          one: "",
          two: "",
          three: comparisonString, // Ensure comparisonString is defined
          four: "1",
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });

        setInfoo(firstResponse.data);
        const parsedData = JSON.parse(firstResponse.data);
        setQuoteId(parsedData.quote_id);
        setMessage("Create Comparison Successfully");

        setTimeout(() => {
          setMessage("");  // Clears the message after 3 seconds
        }, 3000);

        // if (parsedData.message) {
        //   alert(parsedData.message);
        // }

        if (parsedData.message === "Quote added successfully") {
          console.log("Quote added successfully, calling the second web service...");

          // Second web service call: setComaparPoint
          const secondResponse = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/setCompareMasterDetails', {
            one: parsedData.quote_id,
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
          });

          console.log('Second web service response:', secondResponse.data);

          // After the second web service completes successfully, make the final getComaparisionPoint call
          console.log("Both web services completed, now calling the final getComaparisionPoint...");

          const finalResponse = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/getQuoteMasterReportDetails', {
            one: parsedData.quote_id,
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
          });

          setReport(finalResponse.data);
          const data = finalResponse.data.jsArray;
          setComparisonData(data); // Store the API response data
          console.log("comparisonData", finalResponse.data.jsArray);
        }
      } catch (error) {
        console.error('Error during the web service calls!', error);
      } finally {
        setLoading(false); // Set loading to false after all API calls are finished
      }
    };

    fetchData(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures it runs only once when the component mounts
  // Helper function to group products by brand dynamically
  const groupProductsByBrand = (data) => {
    const brands = {};  // Use an empty object to store brands dynamically

    data.forEach((row) => {
      const brandName = row.btb_brand_name;  // Get brand name dynamically
      if (!brands[brandName]) {
        brands[brandName] = [];
      }

      brands[brandName].push({
        product: row.btp_product_name,
        productCode: row.btp_product_code_no,
        image: row.btp_image1,
        mrp: row.btp_mrp_price,
        discount: row.btp_max_discount,
        salePrice: row.sale_rate,
      });
    });

    return brands;
  };

  const groupedProducts = groupProductsByBrand(comparisonData);
  const brandNames = Object.keys(groupedProducts);  // Get all unique brand names

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "100px" }}>
          {/* <button 
            type="button" 
            className="btn btn-outline-success btn-sm" 
            onClick={ShowComparision} 
            style={{ marginRight: "5px" }}
          >
            Show Comparison
          </button> */}

          <Link to={'/jaq'} type="button" className="btn btn-outline-danger btn-sm" style={{ marginRight: "5px" }}>
            Add More Components
          </Link>

          {Object.values(groupedProducts).some(brandProducts => brandProducts.length > 0) && (
  <button
    type="button"
    className="btn btn-outline-danger btn-sm"
    onClick={handleClearComparison}
  >
    Clear Comparison
  </button>
)}
        </div>

 



<div className="comparison-container mt-4 bg-light d-flex" style={{borderRadius:"20px", textAlign:"center",justifyContent:"center"}}>
  <div className="row justify-content-evenly " style={{position: "fixed",
    width: "100%", zIndex:"1000", background:"aquamarine", alignItems:"center"}}>
    {brandNames.map((brand) => (
      <div key={brand} className="col-md-2 ">
        <h3 style={{ textAlign: 'center', color: '#000000', position: "sticky", marginBottom:"0"}}>{brand}</h3>
      </div>
    ))}
  </div>
</div>



<div className="comparison-container py-5">
  <div className="row justify-content-evenly" >
    {brandNames.map((brand) => (
      <div key={brand} className="col-md-2 mb-2 d-grid justify-content-center">
        <div style={{ padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
          {/* <h3 style={{ textAlign: 'center', color: '#000000', position: "sticky" }}>{brand}</h3> */}

          {/* Display products for the current brand */}
          {groupedProducts[brand].map((product, index) => (
            <div key={index} className="card custom-card rounded-lg mb-4">
              <img
                src={product.image ? `http://infinitykids.co.in:5000${product.image}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                className="card-img img-fluid"
                alt={product.product}
              />
              <div className="card-body">
                <h5 className="card-title text-center text-dark">{product.product}</h5>
                <p className="card-text text-muted"><b>Code: <span>{product.productCode}</span></b></p>
                <p className="card-text text-primary">MRP: <span>₹{product.mrp}</span></p>
                <p className="card-text font-weight-bold">Sale Price: <span>₹{product.salePrice}</span></p>
                <p className="card-text text-success">Discount: <span>{product.discount}%</span></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
</div>

{/* Total Price Section outside the comparison-container */}
<div className="comparison-container mt-4">
  <div className="row justify-content-evenly">
    {brandNames.map((brand) => (
      <div key={brand} className="col-md-3 text-center">
        <div className="custom-card rounded-lg">
          <div className="text-dark font-weight-bold">
            Total: ₹
            {groupedProducts[brand].reduce((total, product) => total + parseFloat(product.salePrice || 0), 0).toFixed(2)}
          </div>
        </div>
      </div>
    ))}
  </div>
</div>





      </div>

      <InfoCard />
    </>
  );
};

export default ComparisonProduct;
