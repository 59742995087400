import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

export default function Brands() {
  return (
   <>
   <div className='container'>
   <div className="row">
  {/* First Column (Left side) */}
  <div className="col col-lg-5 col-12">
    <div className="row d-flex justify-content-evenly mt-3">
      {/* First item */}
      {/* <div className="col-6 col-md-4 col-lg-6">
        <div className="d-flex flex-column mb-3">
          <div className="p-2 d-flex justify-content-evenly">
            <Link to="/jaq">
              <img
                src="https://media.istockphoto.com/id/1408740166/photo/contemporary-bathroom-design-with-freestanding-bathtub-and-shower-stall.jpg?s=612x612&w=0&k=20&c=lyW-AAq_Y7cNAwGrLEWMkzt0NKum75AVGG_Bd3ynwfA="
                className="transition-image"
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  boxShadow: "5px 5px 10px gray",
                  padding: "5px",
                }}
              />
            </Link>
          </div>
          <div className="p-0" style={{ textAlign: "center" }}>
            <Link to="/jaq" className="nav-link">
              <b style={{ fontSize: "20px",color:'black' }}>Our Brands</b>
            </Link>
          </div>
        </div>
      </div> */}
      {/* Second item */}
      {/* <div className="col-6 col-md-4 col-lg-6">
        <div className="d-flex flex-column mb-3">
          <div className="p-2 d-flex justify-content-evenly">
            <Link to="/jaq">
              <img
                src="https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI="
                className="transition-image"
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  boxShadow: "5px 5px 10px gray",
                  padding: "5px",
                }}
              />
            </Link>
          </div>
          <div className="p-0" style={{ textAlign: "center" }}>
            <Link to="/jaq" className="nav-link" style={{ fontSize: "20px" }}>
              <b style={{color:'black'}}>Services</b>
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  </div> 
  {/* First column end */}

  {/* Second Column (Right side) */}
  {/* <div className="col col-lg-7 col-12">
    <div className="row d-flex justify-content-evenly mt-3">
      <div className="col-12">
        <video
          src="https://media.istockphoto.com/id/2120396580/video/animation-of-modern-style-luxury-white-bathroom-with-marble-stone-3d-render.mp4?s=mp4-640x640-is&k=20&c=35oKjY3eOW1qVgmCkCYIKYiwTUNbLcEdSe9wsLUnZB4="
          alt=""
          style={{
            height: "200px",
            width: "100%",
            objectFit: "cover",
          }}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  </div>  */}
  {/* Second column end */}
</div>


<h3 className='text-center m-3'>Our Brand And Services</h3>
<div className='container d-flex justify-content-center'>
<div className="row">
      {/* Brands Section */}
      <div className="col-lg-6 text-center">
        <i className="bi bi-asterisk" style={{ fontSize: '50px', color: '#ff7d00' }}></i>
        <h2 className="fw-normal"> Brands</h2>
        <p>
          Explore a curated selection of high-quality bathroom brands. From luxurious fixtures to stylish
          accessories.
        </p>
        <p>
        <Link to="/jaq"  className="nav-link" style={{ fontSize: "20px" }}>
              <button  className="btn btn-info" >Our Brands</button>
            </Link>
        </p>
      </div>

      {/* Services Section */}
      <div className="col-lg-6 text-center">
      <i class="bi bi-boxes" style={{ fontSize: '50px', color: '#ff7d00' }}></i>
        <h2 className="fw-normal">categories</h2>
        <p>
          Our expert bathroom designers will work with you to create a space that blends functionality with
          style.
        </p>
        <p>
        <Link to="/dynB" className="nav-link" style={{ fontSize: "20px" }}>
              <button className='btn btn-info'>categories</button>
            </Link>

        </p>
      </div>

 
    </div>

   </div>
   </div>

   




   
   </>
  )
}
