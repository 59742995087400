import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
   <>
      









<footer>
    <div class="footer-top">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4">
                    <h4>
                      We Are One
                    </h4>
                    <p>WeAreOne Trading Company is a leading business based in Mumbai, </p>
                
                </div>
                <div class="col-md-4">
                    <h4><i class="bi bi-geo-alt"></i>&nbsp;Address</h4>
                    <ul class="address1">
                        <li><i class="fa fa-map-marker"></i> 601, Express Chambers, Sir M.V. Road, Opp. Natraj by Rustomjee, Near WEH Metro Station, Andheri East, Mumbai – 400069.</li>
                        <li>GSTIN: 27AAACW8324N1ZU</li>
                        <li>PAN: AAACW8324N</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h4><i class="bi bi-telephone"></i>&nbsp;Contact Us</h4>
                    
                    <ul style={{listStyle:"none"}}>
                        <li><i class="fa fa-mobile" aria-hidden="true"></i> <span href="tel:+919820037956">Sandeep Doshi, MD:- +91-9820037956</span></li>
                        <li><i class="fa fa-mobile" aria-hidden="true"></i> <span href="tel:+917900084685">Harsh Mehta:-+91-7900084685</span></li>
                        <li><i class="fa fa-mobile" aria-hidden="true"></i> <span href="tel:+919773630600">Krishna Madhale:- +91-9773630600</span></li>
                        <li><i class="fa fa-envelope"></i><a href="mailto:info@test.com">Email:-info@test.com</a></li>

                        </ul>
                </div>
            </div>
        </div>
    </div>
</footer>



























   </>
  )
}


 {/* <div className='col mt-1'>
                        <h5>Useful links</h5>
                        <ul style={{ listStyleType: 'none'}}>
                            <li>FAQ For Customer</li>
                            <li>FAQ For Shop</li>
                            <li>Location</li>
                            <li>Contact</li>
                        </ul>
                    </div> */}
                    {/* <div className='col mt-1'>
                        <h5>My Accounts</h5>
                        <ul style={{ listStyleType: 'none'}}>
                            <li>My Account</li>
                            <li>Returns</li>
                            <li>Order History</li>
                            <li>Order Tracking</li>
                        </ul>
                    </div> */}