import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSheetPlastic, faUsers, faCopyright, faPercent, faStar } from '@fortawesome/free-solid-svg-icons';

export default function AboutUs() {
  return (
    <div>
      <div className='container' style={{ paddingTop: "100px" }}>
        <div className='row'>
          <div className='col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center'>
            <img
              src='https://media.istockphoto.com/id/1456385743/photo/a-luxury-bathroom-with-a-walk-in-tiled-shower-and-standalone-tub.jpg?s=612x612&w=0&k=20&c=12Do8hUY8yzv1kGVZ0lysF9LGD-5nZB1f0_Uz3mbwPU='
              alt=''
              style={{ height: "400px", borderRadius: "10px", maxWidth: "100%" }}
            />
          </div>

          <div className='col-lg-6 col-md-12 col-sm-12'>
            <h3 style={{ color: "#ff004f", marginTop: "10px" }}>
              ABOUT US
            </h3>
            <p style={{ color: "gray", fontFamily: "Poppins" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;Elevate your everyday experience with a bathroom designed to inspire tranquility and luxury.From minimalist modern designs to classic, timeless aesthetics, there are a wide range of styles to suit your unique preferences. Top-quality materials, innovative storage solutions, and custom touches make your bathroom not only beautiful but also efficient and organized.
            </p>
          </div>
        </div>
      </div>

      {/* -----------------------------------------Icons div start here------------------------------------------------------ */}
      {/* <div className='d-flex justify-content-evenly' style={{marginTop:"10px"}}>
        <div className='row d-flex justify-content-evenly' style={{width:"100%"}}>
        <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faSheetPlastic} style={{ color: '#0ea6ad', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>5yrs+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Experience</h5>
            </div>
          </div>
          
          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faUsers} style={{ color: '#bce906', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>5000+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Clients</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faCopyright} style={{ color: '#d50f90 ', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>10+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Brands</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faPercent} style={{ color: '#0bb9d8 ', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>10%</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Discounts</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faStar} style={{ color: '#FFC300 ', fontSize: '5rem',marginRight:"20px" }} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>4.5+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Ratings</h5>
            </div>
          </div>

          
          </div>  
          </div> */}

      {/* -----------------------------------------Icons div ends here------------------------------------------------------ */}

      <div className='container'>
        <div className='row mt-3'>

          <div className='col col-lg-6 col-md-12 col-12' style={{ marginRight: "80px" }} >

            <h3 style={{ color: "#ff004f" }}>
              ABOUT OUR PRODUCTS
            </h3>
            <p style={{ color: "gray", fontFamily: "Poppins" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;Transform your bathroom into a sanctuary with our expert interior design services. Whether you're looking for a sleek, modern retreat or a timeless, elegant space, we specialize in creating bathrooms that blend beauty, functionality, and comfort.
              - Quality Trust
              <br></br>
              - Upto 10 years warrenty
              <br></br>
              - Available in all sizes
              <br></br>
              - Attractive colors
              <br></br>
              - Number of choices
              <br></br>
              - Reasonable rate
            </p>

          </div>     {/* col 6 div end here*/}



          <div className='col col-lg-5 col-md-12 col-12' >

            <div className='row mt-3'>
              <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="https://media.istockphoto.com/id/1462209531/photo/a-bathroom-with-a-dark-cabinet-and-tiled-shower-looking-towards-a-bedroom.jpg?s=612x612&w=0&k=20&c=SzIOunwkkqIWVejk4xYqWZLuAV7FG7sG6HO1P8KKqd0=" style={{ height: "400px", width: "100%", borderRadius: "10%" }} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="https://media.istockphoto.com/id/2155877016/photo/modern-hollywood-hills-home-bathroom-in-los-angeles-california-with-a-remodel-of-an-older.jpg?s=612x612&w=0&k=20&c=sq7rVEuhxZ0OA04Z_I2VX-Mv9NSE7ooYaDuAK8ULlg0=" style={{ height: "400px", width: "100%", borderRadius: "10%" }} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="https://media.istockphoto.com/id/640092090/photo/amazing-master-bathroom-with-large-glass-walk-in-shower.jpg?s=612x612&w=0&k=20&c=kEWlnbP4qR03rxKvjj_5sEW348_ugYX9Vr2DnleSruc=" style={{ height: "400px", width: "100%", borderRadius: "10%" }} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>



            </div>             {/* first row end here */}
            {/* --------------------------------------------------------------------------- */}
          </div>      {/* col 5 div end here*/}


        </div>
      </div>


    </div>



  )
}
