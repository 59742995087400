import React,{useEffect} from 'react'
import MenuBar from './MenuBar'
import SlideCards from './SlideCards'
import Slider from './Slider'
import TapSlider from './TapSlider'
import JaquarInfo from './JaquarInfo'
import DynBrands from './DynBrands'
import Items from './Items'

export default function Jaquar() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
  }, []);
  return (
    <div>
      <Items/>
      {/* <DynBrands/> */}
    
        {/* <MenuBar/> */}
        <JaquarInfo/>
       {/* <TapSlider/> */}

    </div>
  )
}
