import React from 'react'

export default function JaquarInfo() {
  return (
   <>
   

  

  



       {/*  container div end here */}



   </>
  )
}
