import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ProductModal from './ProductModal'; // Import the modal component

export default function Products(props) {
  const [products, setProducts] = useState([]); // State to hold the product data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showModal, setShowModal] = useState(false); // State to show/hide the modal
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold the selected product details
  const [isComparing, setIsComparing] = useState(false); // Track if the comparison is being processed
  const [addProductMessage, setAddProductMessage] = useState('');
  // const [comparisonList, setComparisonList] = useState(
  //   JSON.parse(sessionStorage.getItem('comparisonList')) || []
  // );
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );

  useEffect(() => {
    // Save comparison list to sessionStorage on change
    sessionStorage.setItem('comparisonList', JSON.stringify(comparisonList));
  }, [comparisonList]);


  // Function to add a product to the comparison list
  const addProductToComparison = (productId) => {
    if (comparisonList.length >= 20) {
      alert('You can only compare up to 20 products.');
      return;
    }

    setComparisonList((prevList) => {
      return [...prevList, productId];
    });
  };
  // Save comparison list to session storage on change
  // sessionStorage.setItem('comparisonList', JSON.stringify(comparisonList));
  console.log( 'ppppppppppppp1',JSON.parse(sessionStorage.getItem('comparisonList')));

  const itemId = sessionStorage.getItem('itemId');
  console.log("itemId",itemId);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        // Replace with your actual API endpoint
        // const res = await axios.post('http://infinitykids.co.in:5000/proxy/brandProductpoint', {
        
          const res = await axios.post('http://midbserver.co.in:8285/trading_application/rest/menuService/getBrandProductDetails', {
          one: props.brandId,                   //it is categoryid
          two:itemId,                          //it is brandid
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });
        setProducts(res.data); // Set the fetched data to state
        setLoading(false); // End loading
        console.log(res.data); // Log to verify structure
      } catch (error) {
        setLoading(false); // End loading on error
        setError(error.message); // Set error message
        console.error(error);
      }
    };

    fetchData();
  }, [props.brandId]); // Refetch data when brandId changes

  const handleViewProductClick = (product) => {
    setSelectedProduct(product); // Set the selected product
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedProduct(null); // Clear selected product
  };

  if (loading) {
    return <div>Loading products...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Show the "No products available" message only when a brand is selected
  if (props.brandId && Array.isArray(products) && products.length === 0) {
    return <div style={{color:"#1f94b7", marginLeft:"110px", fontSize:"18px"}}>No products available for {props.brandName} brand.</div>;
  }

  //Add to comparision
  const handleAddToComparison = (id) => {
    if (comparisonList.length >= 20) {
      alert('You can only compare up to 20 products.');
    } else {
      const newComparisonList = [...comparisonList, id];
      setComparisonList(newComparisonList);
    }

   alert('Product added to comparison');
  

  // Remove the message after 2 seconds
  setTimeout(() => {
    setAddProductMessage('');
  }, 2000); // Message will disappear after 2 seconds
  };


  return (
    <>
      <div className='container mt-1'>
        <h3 style={{color:"rgb(255, 0, 79)", fontWeight: "900px", textAlign: "center" }}>Products for Category: {props.brandName}</h3>
        <div className='row'>
          {products.map((product, index) => (

            <div key={index} className='col col-12 col-lg-3 col-md-4 col-sm-12 mb-4 my-2'>
              <div className="box" style={{ width: "13rem", display: "flex", flexDirection: "column", background: "lavender", padding:"10px", borderRadius:"10px" }}>
                <img style={{height:"100px", width:"100px"}}
                  src={product.btp_image1 ? `http://infinitykids.co.in:5000${product.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}

                  className="card-img-top"
                  alt={product.series_name}
                  
                />
               
                  <p className="card-text" style={{
                            fontSize: '20px',
                            color: "#0d778e",
                            fontWeight: "700",
                            cursor: "pointer",
                            whiteSpace: "nowrap", // Prevent text from wrapping
                            overflow: "hidden",  // Hide the overflow text
                            textOverflow: "ellipsis", // Add ellipsis (...) at the end
                            maxWidth: "180px",   // Set a max width based on your design needs
                            display: "inline-block", // Keep the element inline for truncation
                          }}
                          title={product.btp_product_name}>
                            {product.btp_product_name}</p>
                  
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "0px" }}><small>{product.series_name}</small></p>
                  <p style={{ color: "black",fontSize: "13px", marginBottom: "0px" }}><span>Price: &nbsp;{product.btp_mrp_price}</span></p>
                  <p style={{ color: "black", marginBottom: "0px", fontSize: "13px" }}>
                    <b style={{color:"gray", marginBottom: "0px", fontSize: "13px" }}>  Series:&nbsp;{product.bts_series_name || 'SA1254'}</b>
                  </p>

                  <div style={{ marginTop: "5px",marginBottom:"0px",justifyContent:"center" }}>
                    <button className="btn btn-outline-warning btn-sm" onClick={() => handleViewProductClick(product)} style={{marginBottom:"3px"}}>View</button>
                    &nbsp;  &nbsp;
                    <button  className="btn btn-outline-success btn-sm"
                    onClick={()=>addProductToComparison(product.btp_product_id)}
                    disabled={isComparing} // Disable the button while the request is in progress
                    >Add To Quote</button>
                    

                  </div>    
                </div>
              {/* Display the message for adding product to comparison */}
              {/* {addProductMessage && (
                      <div style={{ marginTop: "10px", color: "green", fontWeight: "bold", textAlign: "center" }}>
                        {addProductMessage}
                      </div>
                    )} */}
            </div>
          ))}
        </div>
      </div>

      {/* Pass props to ProductModal component */}
      <ProductModal 
        product={selectedProduct} 
        show={showModal} 
        onClose={handleCloseModal}
        comparisonList={comparisonList}
          addProductToComparison={addProductToComparison} 
      />

<div className='container my-5 py-5'>
    <div className='row mt-3'>
        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1063241040/photo/modern-contemporary-bathroom-with-nature-view-3d-render.jpg?s=612x612&w=0&k=20&c=ZjH6NIiefN-PLKcOvJfFgiBEMvYU2JAmKBzSGu3SEEU=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1308282338/photo/modern-bathroom-interior-stock-photo.jpg?s=612x612&w=0&k=20&c=r6qXSudX7P7YOzYsuHsQ_6pjAekJOF4XnEpdrtfqmsg=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4'>
        <img src='https://media.istockphoto.com/id/1439692133/photo/white-round-side-table-in-modern-and-luxury-design-bathroom-and-tropical-banana-tree-with.jpg?s=612x612&w=0&k=20&c=2ZsJUvf-bHBCrP8F6GiRhhvoM8dAiw3kBB9TxBigeao=' style={{borderRadius:"10px" ,width:"100%",height:"250px"}}></img>

        </div>

        </div>      {/*  row div end here */}
    </div>

    </>
  );

}
