import React from 'react'
import './Home.css'
import MenuBar from '../component2/MenuBar'
import CompoCards from '../component2/CompoCards'
import InfoCard from '../component2/InfoCard'
import SlideCards from '../component2/SlideCards'
import Slider from '../component2/Slider'
import Demo from '../component2/demo'
import Brands from '../component2/Brands'
import DynBrands from '../component2/DynBrands'

export default function Homee() {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row '>

<div className='container-fluid m-0 p-0' >
  
<div id="carouselExampleCaptions" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="/img/mid-century-interior-design.jpg" class="d-block w-100" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
        <h5>We Are One</h5>
        <p>Create a space that inspires—where relaxation meets style, and every detail reflects your unique taste.</p>
      </div>
    </div>
    <div class="carousel-item">
    <img src="/img/realistic-interior-design-with-furniture.jpg"/>
    <div class="carousel-caption d-none d-md-block">
        <h5>Transform Your Bathroom into a Sanctuary</h5>
        <p>Design a space that blends luxury, comfort, and style—where every detail is crafted just for you.</p>
      </div>
      
      
    </div>
    <div class="carousel-item">
    <img src="/img/pexels-heyho-7174404.jpg" />
    <div class="carousel-caption d-none d-md-block">
        <h5>Crafted for Comfort & Functionality</h5>
        <p>Experience the perfect balance of aesthetics and practicality with custom storage, innovative designs, and high-quality finishes.</p>
      </div>
 
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


{/* carousal code  */}
</div>     {/*col div end here*/}


{/* carousal code  */}


<Brands/>
 {/* <CompoCards/>  */}
<InfoCard/>


{/* <SlideCards/> */}
{/* <Slider/> */}


        </div>
      </div>
    </div>
  )
}
