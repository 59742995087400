import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';

export default function App() {

  // useEffect(() => {
  //   // Refresh the page when the component mounts
  //   window.location.reload();
  // }, []); // Empty dependency array ensures it runs only once, when the component mounts
  
  const navigate = useNavigate(); // Initialize navigate function
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication status when the app loads
  useEffect(() => {
    const storedAuthStatus = sessionStorage.getItem('isAuthenticated');
    console.log('storedAuthStatus', storedAuthStatus);
    if (storedAuthStatus === 'true') {
      setIsAuthenticated(true); // If user is authenticated, set state to true
    } else {
      setIsAuthenticated(false); // Otherwise, user is not authenticated
      window.location.reload();
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, []); // Empty dependency array ensures this runs on mount

  if (!isAuthenticated) {
    return null; // Optionally render nothing or a loading state while checking authentication
  }

  return (
    <div>
      <NavBar />
      {/* Other authenticated content goes here */}
    </div>
  );
}
